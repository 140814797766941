@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Digital_Numbers";
  src: local("Digital_Numbers"), url("./fonts/DS-DIGI.TTF") format("truetype");
}

@font-face {
  font-family: "HK_Grotesk";
  src: local("HK_Grotesk"),
    url("./fonts/HKGrotesk-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Brandon_Grotesque";
  src: local("Brandon_Grotesque"),
    url("./fonts/Brandon_reg.otf") format("opentype");
}

@font-face {
  font-family: "Andale_Mono";
  font-style: normal;
  font-weight: normal;
  src: local("Andale_Mono"), url("./fonts/ANDALEMO.woff") format("woff");
}
body {
  margin: 0;
  font-family: "Brandon Grotesque", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

code,
kbd,
samp,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: none;
}

/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 15rem;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #f5c950;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -12px; /* Centers thumb on the track */

  /*custom styles*/
  background-color: #f5c950;
  height: 2rem;
  width: 1rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  border: 1px solid #f5c950;
  outline: 3px solid #f5c950;
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: #f5c950;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: #f5c950;
  height: 2rem;
  width: 1rem;
}

input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid #f5c950;
  outline: 3px solid #f5c950;
  outline-offset: 0.125rem;
}
